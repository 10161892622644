export enum EAggregators {
  CREDIT_KARMA = "CreditKarma",
  CREDIT_KARMA_STORE_CARD = "CreditKarmaStoreCard",
  CLEARSCORE = "Clearscore",
  CLEARSCORE_STORE_CARD = "ClearscoreStoreCard",
  TOTALLY_MONEY = "TotallyMoney",
  TOTALLY_MONEY_STORE_CARD = "TotallyMoneyStoreCard",
  MONEY_SUPERMARKET = "MoneySupermarket",
  VANQUIS = "Vanquis",
  MONEVO = "Monevo",
  TUK = "Tuk",
}

export const buildBitsUniversalLink = (aggregator: string, aggregatorUid: string) => {
  if (
    !process.env.REACT_APP_BITS_UNIVERSAL_LINK ||
    !process.env.REACT_APP_BITS_UNIVERSAL_LINK_HOST_NAME ||
    !process.env.REACT_APP_BITS_UNIVERSAL_LINK_ISI_CODE
  ) {
    return "";
  }
  const encodedUniversalLink = `${process.env.REACT_APP_BITS_UNIVERSAL_LINK.replace(
    "aggregatorplaceholder",
    aggregator
  ).replace("aggregatoruidplaceholder", aggregatorUid)}`;
  return `https://${process.env.REACT_APP_BITS_UNIVERSAL_LINK_HOST_NAME}.page.link/?link=${encodedUniversalLink}&apn=com.getbits&ibi=com.imagineleap.bits&isi=${process.env.REACT_APP_BITS_UNIVERSAL_LINK_ISI_CODE}&ius=bits-app&ofl=https://www.getbits.app`;
};
